<template>
  <div v-if="isDataReady">
    <div class="h-full w-full space-y-4">
      <div class="rounded border border-gray-800 bg-gray-800/25 p-3">
        <ChartGasFee />
      </div>
      <div v-if="gasFees.length != 0">
        <div class="w-full rounded border border-gray-800 bg-gray-800/25">
          <div class="flex flex-col p-3">
            <table>
              <thead>
                <tr class="border-none">
                  <th></th>
                  <th class="px-3 py-0 text-right text-xs font-normal normal-case text-gray-300">Standard</th>
                  <th class="px-3 py-0 text-right text-xs font-normal normal-case text-gray-300">Fast</th>
                  <th class="px-3 py-0 !text-right text-xs font-normal normal-case text-gray-300">Instant</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-none">
                  <td class="py-0"></td>
                  <template v-for="(fee, idx) in gweiFee" :key="idx">
                    <td class="whitespace-nowrap px-3 text-right text-xs font-medium">
                      {{ decimals(fee.gwei, 0, 0) }} Gwei
                    </td>
                  </template>
                </tr>
                <tr v-for="(gf, idx) in gasFees" class="border-none" :key="idx">
                  <td class="whitespace-nowrap px-3 py-1 text-xs text-gray-300">{{ gf.description }}</td>
                  <td class="px-3 py-1 text-right">
                    <span class="text-xs text-white">${{ decimals(gf.fees.standard.usd, 2, 2) }}</span>
                  </td>
                  <td class="px-3 py-1 text-right">
                    <span class="text-xs text-white">${{ decimals(gf.fees.fast.usd, 2, 2) }}</span>
                  </td>
                  <td class="px-3 py-1 text-right">
                    <span class="text-xs text-white">${{ decimals(gf.fees.instant.usd, 2, 2) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col pr-8">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
</template>
<script setup>
import table from '@/mixins/table';
import useHttp from '@/composeables/http';
import { decimals } from '@/composeables/filters';
import { ref, onMounted, computed } from 'vue';
import ChartGasFee from '@/components/chart/ChartGasFee.vue';
import { useStore } from 'vuex';

const $http = useHttp();
const $store = useStore();

const gasFees = computed(() => {
  return $store.getters.gasFees;
});

const isDataReady = computed(() => {
  return $store.getters.gasFeesLoader == false;
});

const gweiFee = computed(() => {
  return gasFees.value?.['swap_transaction']?.fees || {};
});
</script>
